import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button';
import ThankYouImage from '../../../assets/images/thankyou-img.png';
import { RouteKeys } from '../../route-keys';
import { actions as registrationActions } from '../../../store/reducers/payment';
import { actions as dashboardActions } from '../../../store/reducers/dashboard';
import { actions as authUserActions } from '../../../store/reducers/authUser';

const OnlinePaymentDetailsPage: React.SFC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  useEffect(() => {
    dispatch(registrationActions.getAdditionalFees());
    dispatch(registrationActions.getCourse(false, false));
    window?.localStorage?.removeItem('selectedAddOn');
  }, []);

  const goBackHome = async () => {
    setLoading(true);
    await dispatch(authUserActions.fetchProfile());
    await dispatch(dashboardActions.fetchPaymentHistory());
    await dispatch(registrationActions.getCourse(false, false));
    setLoading(false);
    history.replace(`/${selectedLanguage}${RouteKeys.Dashboard}`);
  };

  // eslint-disable-next-line class-methods-use-this
  const renderImage = () => {
    return (
      <div className="Thankyou-img">
        <img src={ThankYouImage} className="img-fluid mx-auto" alt="thankyou" />
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderHeader = () => {
    return (
      <h3 className="font-bold text-primary pt-7 pb-2.5 text-[32px]">
        Thank you
      </h3>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  const renderDescription = () => {
    return (
      <p className="text-base mb-5 text-primary font-medium">
        For doing payment with Eco Drive.
      </p>
    );
  };

  const renderBackButton = () => {
    return (
      <button
        className="btn btn-primary"
        onClick={goBackHome}
        disabled={loading}
      >
        {loading ? 'Please wait' : 'Back to Payment'}
      </button>
    );
  };

  const renderBody = () => {
    return (
      <div className="Thankyou text-center my-14">
        <div className="bg-white w-full max-w-[520px] rounded-md py-10 px-5 mx-auto">
          {renderImage()}
          {renderHeader()}
          {renderDescription()}
          {renderBackButton()}
        </div>
      </div>
    );
  };

  return <>{renderBody()}</>;
};

export default OnlinePaymentDetailsPage;

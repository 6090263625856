import React, { useEffect } from 'react';
import './PaymentMethod.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { State } from '../../../store/interfaces';
import {
  paymentModes,
  actions as paymentActions,
} from '../../../store/reducers/payment';
import { PaymentMessage } from '../../../models/payment';
import { ContainerLoader } from '../../../components/loader';
import { useHistory } from 'react-router';
import { RouteKeys } from '../../route-keys';
import { MessageType } from '../../../models/messages';

interface CustomProps {
  gotoPreviousBlock?: () => void;
  selectedAddOn?: any;
  user?: any;
  paymentMethod: paymentModes;
  totalAmount?: number;
  referralAmount?: number;
}

const PaymentRedirect: React.SFC<CustomProps> = (props: CustomProps) => {
  const { onlinePaymentStatus } = useSelector((state: State) => state.payment);
  const selectedLanguage = useSelector(
    (state: any) => state.language.selectedLanguage
  );

  const redirectData = useSelector((state: State) => state.payment);
  const userId = props.user.user ? props.user.user._id : '';
  const urlParams = new URL(window.location.href).searchParams;

  // Check if 'ref' parameter exists
  const ref = urlParams.get('ref');
  useEffect(() => {
    if (ref) {
      const data = {
        type: MessageType.PaymentInformation,
        ref,
      };
      completePayment({ type: MessageType.PaymentInformation, data });
    }
  }, [ref]);
  const history = useHistory();
  const dispatch = useDispatch();

  const completePayment = (data: PaymentMessage) => { 
    const selectedAddOn =window?.localStorage?.getItem('selectedAddOn')?JSON.parse(window?.localStorage?.getItem('selectedAddOn')||''):null
 
    if (selectedAddOn) {
      const retryPayment = onlinePaymentStatus === 'failed' ? true : false;

      const paymentObj = {
        type: window.localStorage.getItem('selectedAddOncode'),
        amount: Number(window.localStorage.getItem('payingAmount')),
        appliedPromoCode: null,
        stage: null,
        paymentMethod:window.localStorage.getItem('paymentMethod'), 
        platform: 'web',
        paymentReference: data.data.ref as string,
        retryPayment,
      };
      dispatch(
        paymentActions.collectAdditionalFees(
          paymentObj,
          userId,
          onPaymentSuccess,
          onPaymentError,
        )
      );
    } else {
      const retryPayment = onlinePaymentStatus === 'failed' ? true : false;

      dispatch(
        paymentActions.verifyPayment(
          data.data,
          retryPayment,
          onPaymentSuccess,
          props.referralAmount,
          onPaymentError
        )
      );
    }
  };

  const onPaymentSuccess = () => {
    if (!props.selectedAddOn) {
      dispatch(paymentActions.getCourse(false, false));
    }
    history.replace(`/${selectedLanguage+RouteKeys.PaymentThankYou}`); 
  };

  const onPaymentError = () => {
    if (!props.selectedAddOn) {
      dispatch(paymentActions.getCourse(false, false));
    }
    history.replace(RouteKeys.PaymentError);
  };

  return (
    <div className="PaymentMethod">
      <ContainerLoader
        height={800}
        text="Payment in progress, please do not close or click the back button..."
      ></ContainerLoader>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  selectedAddOn: state.payment.currectAddOn,
  user: state.authUser,
  paymentMethod: state.payment.modeOfPayment,
  totalAmount: state.payment.summary.totalAmountPaid,
  referralAmount: state.registration?.referralAmount || 0,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRedirect);

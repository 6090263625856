import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import CoursePlan from '../../components/on-boarding/course-plan';
import { actions as registrationActions } from '../../store/reducers/registration';
import { actions as authActions } from '../../store/reducers/authUser';
import CourseTypeLoader from '../../components/on-boarding/course-type-loader';
import User, { StudentCourse } from '../../models/user';
import CourseTab from '../../components/on-boarding/course-tab';
import { ErrorResponse } from '../../store/reducers/error';

interface CustomProps {
  isLoading?: boolean;
  user?: User;
  courses?: any[];
  loadCourses?: (force: boolean, preApplyPromoCode: boolean) => void;
  gotoNextBlock?: () => void;
  addSummary?: (key: string, label: string, value: string) => void;
  selectCourse: (
    course: any,
    courseType: string,
    onSuccess?: () => void
  ) => void;
  logout: () => void;
  hasCourse?: boolean;
}

interface CustomState {
  isExpanded: boolean;
  isSelected: string;
  disableSelect: boolean;
}

class CourseType extends Component<CustomProps, CustomState> {
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isExpanded: false,
      isSelected: '',
      disableSelect: false,
    };
    this.onChangeExpanded = this.onChangeExpanded.bind(this);
    this.onCourseSelected = this.onCourseSelected.bind(this);
  }

  componentDidMount() {
    if (this.props.loadCourses) {
      this.props.loadCourses(false, true);
    }
  }

  onChangeExpanded() {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  }

  async onCourseSelected(courseType: string, course: StudentCourse) {
    this.setState({ disableSelect: true });
    if (this.props.addSummary) {
      this.props.addSummary('coursetype', 'Your choose course as', courseType);
      this.props.addSummary(
        'courseprice',
        'course price is',
        `AED ${course.amount.totalAmount - course.amount.totalDiscount}`
      );
    }
    try {
      if (this.props.selectCourse) {
        await this.props.selectCourse(course, courseType);
        this.setState({ isSelected: course.courseTypeId });
      }
      if (this.props.gotoNextBlock) {
        this.props.gotoNextBlock();
      }
    } catch (err) {
      const typedError = err as ErrorResponse;

      // console.log('error Logging in...1232', err.response.data.message);
      if (typedError.response.data.message === 'User has already done payment') {
        toastr.error(
          'Error',
          'User has already done payment. Please login again'
        );
        this.props.logout();
      } else {
        toastr.error('Error', typedError.response.data.message);
      }
    }
    this.setState({ disableSelect: false });
  }

  renderCoursePlan(course: any, courseType: string, key: string | number) {
    return (
      <CoursePlan
        key={`${key}`}
        course={course}
        onSelect={() => this.onCourseSelected(courseType, course)}
        isExpanded={this.state.isExpanded}
        onChangeExpanded={this.onChangeExpanded}
        selectedId={this.state.isSelected}
        disableSelect={this.state.disableSelect}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLoading() {
    return (
      <>
        <CourseTypeLoader />
        <CourseTypeLoader />
      </>
    );
  }

  renderCourses() {
    return (this.props.courses || []).map((courseData, index) => {
      return this.renderCoursePlan(
        courseData.course,
        courseData.courseType,
        index
      );
    });
  }

  renderCourseTab() {
    const courseArray: any = [];
    this.props.courses?.map((courseData, index) => {
      courseArray.push(courseData.course);
    });

    return this.renderCoursePlanTab(courseArray);
  }

  renderCoursePlanTab(courses: any) {
    return (
      <CourseTab
        courses={courses}
        selectedId={this.state.isSelected}
        onSelect={(courseType, course) => {
          this.onCourseSelected(courseType, course);
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderNoCourse() {
    return (
      <p className="text-muted mb-5">
        Thank you for registering with {process.env.REACT_APP_COMPANY}, Our support
        executive will contact you soon.
      </p>
    );
  }

  render() {
    return (
      <div className="pt-5 sm:pt-7">
        {this.props.isLoading && this.renderLoading()}
        {!this.props.isLoading && this.props.hasCourse && (
          <>
            {this.renderCourseTab()}
            <div className="row">
              <p className="Plan__note">
                <sup>**</sup> License is guaranteed within the amount displayed
                above, subject to completion of the course and RTA requirements.
              </p>
            </div>
          </>
        )}
        {!this.props.isLoading &&
          this.props.hasCourse === false &&
          this.renderNoCourse()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isLoading: state.registration.isLoading,
  user: state.registration.user,
  hasCourse: state.registration.isLoading
    ? false
    : state.registration.hasCourse,
  courses:
    state.registration.isLoading || !state.registration.courses
      ? []
      : Object.keys(state.registration.courses).map((kv) => ({
          courseType: kv,
          course: state.registration.courses[kv],
        })),
});

const mapDispatchToProps = (dispatch: any) => ({
  loadCourses: (force: boolean, preApplyPromoCode: boolean) =>
    dispatch(registrationActions.getCourses(force, preApplyPromoCode)),
  selectCourse: (course: any, courseType: string, onSuccess?: () => void) =>
    dispatch(registrationActions.selectCourse(course, courseType, onSuccess)),

  logout: () => dispatch(authActions.logout()),
});

// CourseType.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   user: PropTypes.shape({}),
//   courses: PropTypes.arrayOf(PropTypes.shape({})),
//   loadCourses: PropTypes.func.isRequired,
//   gotoNextBlock: PropTypes.func.isRequired,
//   addSummary: PropTypes.func.isRequired,
//   selectCourse: PropTypes.func.isRequired,
//   hasCourse: PropTypes.bool.isRequired,
// };

export default connect(mapStateToProps, mapDispatchToProps)(CourseType);

import React, { useEffect } from 'react';
import { createPaymentResponseMessage } from '../../utils/payment';
import { ContainerLoader } from '../../components/loader';

interface CustomProps {
  location: any;
}

const PaymentCallBackPage: React.SFC<CustomProps> = (props) => {
  useEffect(() => {
    window.parent.postMessage(
      createPaymentResponseMessage(props.location),
      '*'
    );
  });
  return <ContainerLoader height={800}></ContainerLoader>;
};

export default PaymentCallBackPage;

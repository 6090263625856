import { CourseMilestone } from './../../models/course';
import { PaymentReferenceInfo } from './../../models/payment';
import { getHost } from './../../utils/host';
import { Amounts } from '../../models/amounts';
// import * as UserApi from '../api/user';

import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import api from '../../api';
import { types as commonTypes } from './common';
import User, { ReferralList, StudentCourse } from '../../models/user';
import { State } from '../interfaces';
import { RouteKeys } from '../../containers/route-keys';
import { trackPurchase } from '../../utils/analytics';
import { ErrorResponse } from './error';
import { number } from 'prop-types';

// action types
export const types = {
  // TODO: move to common place
  SIGNUP_REQUEST: 'AUTH/SIGNUP_REQUEST',

  // update details
  UPDATE_DETAILS_REQUEST: 'REGISTRATION/UPDATE_DETAILS_REQUEST',
  UPDATE_DETAILS_SUCCESS: 'REGISTRATION/UPDATE_DETAILS_SUCCESS',
  UPDATE_DETAILS_ERROR: 'REGISTRATION/UPDATE_DETAILS_ERROR',

  // course
  GET_COURSES_REQUEST: 'REGISTRATION/GET_COURSES_REQUEST',
  GET_COURSES_SUCCESS: 'REGISTRATION/GET_COURSES_SUCCESS',
  GET_COURSES_ERROR: 'REGISTRATION/GET_COURSES_ERROR',

  CHOOSE_COURSE_REQUEST: 'REGISTRATION/CHOOSE_COURSE_REQUEST',
  CHOOSE_COURSE_SUCCESS: 'REGISTRATION/CHOOSE_COURSE_SUCCESS',
  CHOOSE_COURSE_ERROR: 'REGISTRATION/CHOOSE_COURSE_ERROR',

  // promotion
  REMOVE_PROMOTION: 'REGISTRATION/REMOVE_PROMOTION',
  APPLY_PROMOTION: 'REGISTRATION/APPLY_PROMOTION',

  // payment plan
  CHANGE_PAYMENT_PLAN: 'REGISTRATION/CHANGE_PAYMENT_PLAN',
  CHANGE_PAYMENT_METHOD: 'REGISTRATION/CHANGE_PAYMENT_METHOD',
  SELECT_MILESTONE: 'REGISTRATION/SELECT_MILESTONE',

  // payment
  PAYMENT_INITIATE_REQUEST: 'REGISTRATION/PAYMENT_INITIATE_REQUEST',
  PAYMENT_INITIATE_SUCCESS: 'REGISTRATION/PAYMENT_INITIATE_SUCCESS',
  PAYMENT_INITIATE_ERROR: 'REGISTRATION/PAYMENT_INITIATE_ERROR',
  VERIFY_PAYMENT_REQUEST: 'REGISTRATION/VERIFY_PAYMENT_REQUEST',
  VERIFY_PAYMENT_SUCCESS: 'REGISTRATION/VERIFY_PAYMENT_SUCCESS',
  VERIFY_PAYMENT_FAILED: 'REGISTRATION/VERIFY_PAYMENT_FAILED',
  VERIFY_PAYMENT_ERROR: 'REGISTRATION/VERIFY_PAYMENT_ERROR',

  // PROMOCODE
  PROMOCODE_INITIATE_REQUEST: 'REGISTRATION/PROMOCODE_INITIATE_REQUEST',
  PROMOCODE_INITIATE_SUCCESS: 'REGISTRATION/PROMOCODE_INITIATE_SUCCESS',
  PROMOCODE_INITIATE_ERROR: 'REGISTRATION/PROMOCODE_INITIATE_ERROR',

  // PROMOCODE
  APPLY_PROMOCODE_INITIATE_REQUEST:
    'REGISTRATION/APPLY_PROMOCODE_INITIATE_REQUEST',
  APPLY_PROMOCODE_INITIATE_SUCCESS:
    'REGISTRATION/APPLY_PROMOCODE_INITIATE_SUCCESS',
  APPLY_PROMOCODE_INITIATE_ERROR: 'REGISTRATION/APPLY_ROMOCODE_INITIATE_ERROR',
  APPLY_PROMOCODE_CLOSE: 'REGISTRATION/APPLY_PROMOCODE_CLOSE',

  // REFERRAL CODE
  HAS_REFERRAL: 'REGISTRATION/HAS_REFERRAL',
  REFERRAL_INITIATE_REQUEST: 'REGISTRATION/REFERRAL_INITIATE_REQUEST',
  REFERRAL_INITIATE_SUCCESS: 'REGISTRATION/REFERRAL_INITIATE_SUCCESS',
  REFERRAL_INITIATE_ERROR: 'REGISTRATION/REFERRAL_INITIATE_ERROR',
  APPLY_REFERRAL_INITIATE_REQUEST:
    'REGISTRATION/APPLY_REFERRAL_INITIATE_REQUEST',
  APPLY_REFERRAL_INITIATE_SUCCESS:
    'REGISTRATION/APPLY_REFERRAL_INITIATE_SUCCESS',
  APPLY_REFERRAL_INITIATE_ERROR: 'REGISTRATION/APPLY_REFERRAL_INITIATE_ERROR',
  APPLY_REFERRAL_CLOSE: 'REGISTRATION/APPLY_REFERRAL_CLOSE',
};

export enum registrationStages {
  registration = 'Registration',
  profileDetails = 'Profile Details',
  courseSelection = 'Course Selection',
  courseSelected = 'Course Selected',
  completePayment = 'Complete Payment',
  finished = 'Finished',
}

export enum originalRegistrationStages {
  profileDetails = 'personalDetails',
  courseSelection = 'courseDetails',
  courseSelected = 'courseSelection',
  finished = 'homeScreen',
  onlinePaymentInitiated = 'paymentConfirmation',
  onlinePaymentFailed = 'paymentFailed',
}

export enum paymentModes {
  online = 'Online',
  edsCenter = 'At EDC Center',
}

export enum OnlinePaymentStatus {
  initiated = 'Initited',
  processing = 'processing',
  verifying = 'verifying',
  completed = 'completed',
  failed = 'failed',
}

export enum paymentPlans {
  fullPayment = 'Full Payment',
  stagePayment = 'Stage Payment',
}

export enum paymentErrorCodes {
  threeDSErrorOne = '3DS authentication was attempted but was not or could not be completed; possible reasons being either the card or its Issuing Bank has yet to participate in 3DS, or cardholder ran out of time to authorize.',
  threeDSErrorTwo = '3DS authentication is either failed or could not be attempted; possible reasons being both card and Issuing Bank are not secured by 3DS, technical errors, or improper configuration.',
  authError = 'Authentication was attempted but was not or could not be completed; possible reasons being either the card or its Issuing Bank has yet to participate in 3DS.',
}

export enum paymentErrors {
  THREE_DS_NOT_AUTHENTICATED = 'The Payment transaction is failed due to non-compliance to 3DS Authentication.',
  AUTHORISATION_FAILED = 'The Payment transaction is rejected due to failure in authorization',
}

const summaryMapReduce: {
  initial: Amounts;
  reduce: (prev: any, current: any) => Amounts;
} = {
  initial: {
    amountRemaining: 0,
    noTaxableAmount: 0,
    totalAmountWithDiscount: 0,
    totalAmountWithTax: 0,
    roundOff: 0,
    taxableAmount: 0,
    totalAmount: 0,
    totalAmountPaid: 0,
    totalDiscount: 0,
    totalTax: 0,
    totalTaxPaid: 0,
    processigFee: 0,
    drivingCenter: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
      price: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    },
    rta: {
      pricePerQuantity: 0,
      taxPerQuantity: 0,
      totalAmount: 0,
      totalAmountPaid: 0,
      totalDiscount: 0,
      totalTax: 0,
      totalTaxPaid: 0,
      price: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    },
  },
  reduce: (prev: any, current: any) => {
    return {
      amountRemaining: prev.amountRemaining + current.amount.amountRemaining,
      noTaxableAmount: prev.noTaxableAmount + current.amount.noTaxableAmount,
      // quantity: prev.quantity + current.amount.quantity,
      roundOff: prev.roundOff + current.amount.roundOff,
      taxableAmount: prev.taxableAmount + current.amount.taxableAmount,
      totalAmount: prev.totalAmount + current.amount.totalAmount,
      totalAmountPaid: prev.totalAmountPaid + current.amount.totalAmountPaid,
      totalDiscount: prev.totalDiscount + current.amount.totalDiscount,
      totalTax: prev.totalTax + current.amount.totalTax,
      totalTaxPaid: prev.totalTaxPaid + current.amount.totalTaxPaid,
      processigFee: 0,
      totalAmountWithDiscount:
        prev.totalAmountWithDiscount + current.amount.totalAmountWithDiscount,
      totalAmountWithTax:
        prev.totalAmountWithTax + current.amount.totalAmountWithTax,
      drivingCenter: {
        pricePerQuantity:
          prev.drivingCenter.pricePerQuantity +
          current.amount.drivingCenter.pricePerQuantity,
        taxPerQuantity:
          prev.drivingCenter.taxPerQuantity +
          current.amount.drivingCenter.taxPerQuantity,
        totalAmount:
          prev.drivingCenter.totalAmount +
          current.amount.drivingCenter.totalAmount,
        totalAmountPaid:
          prev.drivingCenter.totalAmountPaid +
          current.amount.drivingCenter.totalAmountPaid,
        totalDiscount:
          prev.drivingCenter.totalDiscount +
          current.amount.drivingCenter.totalDiscount,
        totalTax:
          prev.drivingCenter.totalTax + current.amount.drivingCenter.totalTax,
        totalTaxPaid:
          prev.drivingCenter.totalTaxPaid +
          current.amount.drivingCenter.totalTaxPaid,
        price: 0,
        amount: 0,
        discount: 0,
        netAmount: 0,
      },
      rta: {
        pricePerQuantity:
          prev.rta.pricePerQuantity + current.amount.rta.pricePerQuantity,
        taxPerQuantity:
          prev.rta.taxPerQuantity + current.amount.rta.taxPerQuantity,
        totalAmount: prev.rta.totalAmount + current.amount.rta.totalAmount,
        totalAmountPaid:
          prev.rta.totalAmountPaid + current.amount.rta.totalAmountPaid,
        totalDiscount:
          prev.rta.totalDiscount + current.amount.rta.totalDiscount,
        totalTax: prev.rta.totalTax + current.amount.rta.totalTax,
        totalTaxPaid: prev.rta.totalTaxPaid + current.amount.rta.totalTaxPaid,
        price: 0,
        amount: 0,
        discount: 0,
        netAmount: 0,
      },
    };
  },
};

export interface RegistrationState {
  isLoading: boolean;
  courses?: any;
  course?: any;
  hasCourse?: boolean;
  user?: User;
  currentStage: registrationStages;
  summary: Amounts;
  stagePayment: {
    milestones: any[];
    summary: Amounts;
  };
  fullPayment: {
    summary: Amounts;
  };
  paymentPlan: paymentPlans;
  modeOfPayment: paymentModes;
  courseType?: string;
  promotion?: any;
  onlinePaymentInfo?: any;
  onlinePaymentStatus?: OnlinePaymentStatus;
  promotions?: any[];
  promoCode?: string;
  showPromoCodeBox?: boolean;
  referralList?: [];
  referralCode?: string;
  hasReferral: boolean;
  referralAmount: number;
  referralType: string;
  tokenBalanceAmount?: number;
  paymentStatus?:string;
}

// initial state
const initialState: RegistrationState = {
  isLoading: false,
  courses: undefined,
  course: undefined,
  user: undefined,
  currentStage: registrationStages.profileDetails,
  summary: summaryMapReduce.initial,
  stagePayment: {
    milestones: [],
    summary: summaryMapReduce.initial,
  },
  fullPayment: {
    summary: summaryMapReduce.initial,
  },
  paymentPlan: paymentPlans.fullPayment,
  modeOfPayment: paymentModes.online,
  courseType: undefined,
  promotion: undefined,
  onlinePaymentStatus: undefined,
  promoCode: undefined,
  promotions: [],
  showPromoCodeBox: false,
  hasReferral: false,
  referralCode: '',
  referralList: [],
  referralAmount: 0,
  referralType: '',
  tokenBalanceAmount: 0,
};

const formatCourse = (course?: StudentCourse) => {
  if (!course || !course.milestones) {
    return course;
  }
  return {
    ...course,
    milestones: course.milestones
      .map((ml: CourseMilestone) => {
        return {
          ...ml,
          stages: ml.instanceIds
            .map((stId: any) =>
              course.stages.find((st: any) => st.stage._id === stId)
            )
            .filter((sta: any) => sta !== undefined),
        };
      })
      .map((ml: any) => {
        return {
          ...ml,
          amount: ml.stages.reduce(
            summaryMapReduce.reduce,
            summaryMapReduce.initial
          ),
        };
      }),
  };
};

const getSummaryFromMilestones = (milestones: any[]) => {
  return milestones.reduce(summaryMapReduce.reduce, summaryMapReduce.initial);
};

const getCurrentStageFromUser = (user: any) => {
  switch (user.currentStage) {
    case originalRegistrationStages.profileDetails:
      return registrationStages.profileDetails;
    case originalRegistrationStages.courseSelection:
    case originalRegistrationStages.onlinePaymentFailed:
      return registrationStages.courseSelection;
    case originalRegistrationStages.courseSelected:
      return registrationStages.courseSelected;
    case originalRegistrationStages.onlinePaymentInitiated:
      return registrationStages.completePayment;
    case originalRegistrationStages.finished:
      return registrationStages.finished;
    default:
      return registrationStages.profileDetails;
  }
};
const getPaymentStatusFromCurrentStage = (
  user: any
): OnlinePaymentStatus | undefined => {
  switch (user.currentStage) {
    case originalRegistrationStages.profileDetails:
    case originalRegistrationStages.courseSelection:
    case originalRegistrationStages.courseSelected:
      return undefined;
    case originalRegistrationStages.onlinePaymentFailed:
      return OnlinePaymentStatus.failed;
    case originalRegistrationStages.onlinePaymentInitiated:
      return OnlinePaymentStatus.initiated;
    case originalRegistrationStages.finished:
      return OnlinePaymentStatus.completed;
    default:
      return undefined;
  }
};

// reducer
export default (state = initialState, action: any): RegistrationState => {
  switch (action.type) {
    case commonTypes.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.data,
        currentStage: getCurrentStageFromUser(action.data),
        onlinePaymentStatus: getPaymentStatusFromCurrentStage(action.data),
      };
    // details
    case types.UPDATE_DETAILS_REQUEST:
      return { ...state, isLoading: true, onlinePaymentInfo: undefined };
    case types.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.data,
        currentStage: registrationStages.courseSelection,
      };
    case types.UPDATE_DETAILS_ERROR:
      return { ...state, isLoading: false };

    // courses
    case types.GET_COURSES_REQUEST:
      return { ...state, isLoading: true, onlinePaymentInfo: undefined };
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courses: action.data.courses,
        hasCourse: action.data.hasCourse,
        onlinePaymentStatus: undefined,
      };
    case types.GET_COURSES_ERROR:
      return { ...state, isLoading: false };
    case types.CHOOSE_COURSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        summary: summaryMapReduce.initial,
        course: undefined,
        courseType: undefined,
        promotion: undefined,
        stagePayment: {
          ...state.stagePayment,
          milestones: [],
          summary: summaryMapReduce.initial,
        },
        fullPayment: {
          summary: summaryMapReduce.initial,
        },
        paymentPlan: paymentPlans.fullPayment,
        onlinePaymentInfo: undefined,
        onlinePaymentStatus: undefined,
      };
    case types.CHOOSE_COURSE_SUCCESS: {
      if (
        action.data.course === state.course &&
        action.data.courseType === state.courseType
      ) {
        return {
          ...state,
          currentStage: registrationStages.courseSelected,
          referralList: action.data.referralList,
          referralCode: action.data.referralCode,
          referralAmount: action.data.referralAmount,
        };
      }
      const course = action.data.course;
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }
      const { courseType } = action.data;
      const { milestones } = course;
      milestones.sort((a: any, b: any) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      // /const summary = action.data.amount;
      const promotion =
        course.amount.promotions && course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i = i + 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.courseSelected,
        tokenBalanceAmount: action.data.tokenBalanceAmount,
        onlinePaymentInfo: undefined,
        onlinePaymentStatus: undefined,
        promoCode: promotion ? promotion.publicPromoCode : '',
        referralList: action.data.referralList,
        referralCode: action.data.referralCode,
        referralAmount: action.data.referralAmount,
      };
    }

    case types.CHOOSE_COURSE_ERROR:
      return {
        ...state,
        isLoading: false,
        summary: summaryMapReduce.initial,
        course: undefined,
        courseType: undefined,
        promotion: undefined,
        stagePayment: {
          ...state.stagePayment,
          milestones: [],
          summary: summaryMapReduce.initial,
        },
        fullPayment: {
          summary: summaryMapReduce.initial,
        },
        paymentPlan: paymentPlans.fullPayment,
        onlinePaymentInfo: undefined,
        currentStage: registrationStages.courseSelection,
      };

    // milestones
    case types.CHANGE_PAYMENT_PLAN: {
      const plan = action.data;
      if (plan === state.paymentPlan) {
        return state;
      }
      if (plan === paymentPlans.fullPayment) {
        return {
          ...state,
          summary: state.fullPayment.summary,
          paymentPlan: plan,
          onlinePaymentInfo: undefined,
        };
      }
      if (plan === paymentPlans.stagePayment) {
        return {
          ...state,
          summary: state.stagePayment.summary,
          paymentPlan: plan,
          onlinePaymentInfo: undefined,
        };
      }
      return state;
    }
    case types.CHANGE_PAYMENT_METHOD: {
      const mode = action.data;
      if (mode === state.modeOfPayment) {
        return state;
      }
      return {
        ...state,
        modeOfPayment: mode,
        onlinePaymentInfo: undefined,
      };
    }
    case types.SELECT_MILESTONE: {
      if (state.paymentPlan !== paymentPlans.stagePayment) {
        return state;
      }
      const { milestone, selected } = action.data;
      let { milestones } = state.stagePayment;
      const { course } = state;
      let courseMilestones: Array<CourseMilestone & { amount: Amounts }> = (
        course || { milestones: [] }
      ).milestones;
      courseMilestones = courseMilestones.sort((a, b) => a.level - b.level);
      if (selected) {
        milestones = courseMilestones.filter(
          (cm: any) => cm.level <= milestone.level
        );
      } else {
        milestones = courseMilestones.filter(
          (cm: any) => cm.level < milestone.level
        );
      }
      const nextIndex = milestones.length;

      for (let i = nextIndex; i < courseMilestones.length; i = i + 1) {
        const courseMilestone = courseMilestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestones.push(courseMilestone);
      }
      milestones = milestones.sort((a, b) => a.level - b.level);

      const summary = getSummaryFromMilestones(milestones);
      return {
        ...state,
        summary,
        stagePayment: {
          ...state.stagePayment,
          milestones,
          summary,
        },
        onlinePaymentInfo: undefined,
      };
    }

    // payment
    case types.PAYMENT_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        onlinePaymentInfo: undefined,
        onlinePaymentStatus: OnlinePaymentStatus.initiated,
        currentStage: registrationStages.completePayment,
      };
    case types.PAYMENT_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentStage:
          state.modeOfPayment === paymentModes.online
            ? registrationStages.completePayment
            : registrationStages.finished,
        onlinePaymentInfo: action.data,
        onlinePaymentStatus: OnlinePaymentStatus.processing,
      };
    case types.PAYMENT_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        onlinePaymentStatus: undefined,
      };
    // payment
    case types.VERIFY_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        onlinePaymentStatus: OnlinePaymentStatus.verifying,
        onlinePaymentInfo: undefined,
      };
    case types.VERIFY_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onlinePaymentStatus: OnlinePaymentStatus.completed,
        onlinePaymentInfo: undefined,
        currentStage: registrationStages.finished,
      };
    case types.VERIFY_PAYMENT_FAILED:
      return {  
        ...state,
        isLoading: false,
        currentStage: registrationStages.courseSelected,
        onlinePaymentInfo: undefined,
        onlinePaymentStatus: OnlinePaymentStatus.failed,
        paymentStatus:action?.data?.status
      };
    // case types.VERIFY_PAYMENT_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     onlinePaymentStatus: OnlinePaymentStatus.failed,
    //     onlinePaymentInfo: undefined,
    //     currentStage: registrationStages.completePayment,
    //   };

    case types.VERIFY_PAYMENT_ERROR:
       return {
        ...state,
        isLoading: false,
        onlinePaymentStatus: OnlinePaymentStatus.failed,
        onlinePaymentInfo: undefined,
        currentStage: registrationStages.courseSelected,
        paymentStatus:action?.data?.status
      };
  
    // promoCode
    case types.PROMOCODE_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        showPromoCodeBox: true,
      };
    case types.PROMOCODE_INITIATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        promotions: action.data,
      };
    case types.PROMOCODE_INITIATE_ERROR:
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };

    // promoCode
    case types.APPLY_PROMOCODE_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        showPromoCodeBox: false,
      };
    case types.APPLY_PROMOCODE_CLOSE:
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };
    case types.APPLY_PROMOCODE_INITIATE_SUCCESS: {
      const course = action.data;
      if (!course) {
        return {
          ...state,
          isLoading: false,
        };
      }
      const {
        courseType: {
          name: { en },
        },
      } = action.data;
      const { milestones } = course;
      milestones.sort((a: any, b: any) => a.level - b.level);
      const summary = getSummaryFromMilestones(milestones);
      const promotion =
        course.amount.promotions && course.amount.promotions.length > 0
          ? course.amount.promotions[0]
          : null;

      const milestonesForStage = [milestones[0]];
      const nextIndex = 1;

      for (let i = nextIndex; i < milestones.length; i = i + 1) {
        const courseMilestone = milestones[i];
        if (
          !courseMilestone ||
          courseMilestone.amount.totalAmountWithDiscount > 0
        ) {
          break;
        }
        milestonesForStage.push(courseMilestone);
      }
      return {
        ...state,
        isLoading: false,
        summary,
        course,
        courseType: en,
        promotion,
        stagePayment: {
          ...state.stagePayment,
          milestones: milestonesForStage,
          summary: getSummaryFromMilestones(milestonesForStage),
        },
        fullPayment: {
          summary,
        },
        paymentPlan: paymentPlans.fullPayment,
        currentStage: registrationStages.courseSelected,
        onlinePaymentInfo: undefined,
        promoCode:
          action.promoCode || (promotion ? promotion.publicPromoCode : ''),
      };
    }

    case types.APPLY_PROMOCODE_INITIATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        showPromoCodeBox: false,
      };
    }

    case types.HAS_REFERRAL:
      return {
        ...state,
        isLoading: false,
        hasReferral: action.data.hasReferral,
      };

    case types.APPLY_REFERRAL_INITIATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.APPLY_REFERRAL_CLOSE:
      return {
        ...state,
        isLoading: false,
      };
    case types.APPLY_REFERRAL_INITIATE_SUCCESS:
      return {
        ...state,
        referralList: action.data.referralList,
        referralCode: action.data.referralCode,
        referralAmount: action.data.referralAmount,
        referralType: action.data.referralType,
        isLoading: false,
      };

    case types.APPLY_REFERRAL_INITIATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case types.SIGNUP_REQUEST:
    case commonTypes.RESET_DATA:
      return { ...initialState };

    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  updateDetails: (userData: any, onSuccess: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.UPDATE_DETAILS_REQUEST });
    try {
      const response = await api.registration.updateDetails(userData);
      dispatch({
        type: types.UPDATE_DETAILS_SUCCESS,
        data: response.data.userRecord,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({ type: types.UPDATE_DETAILS_ERROR });
      // toastr.error('Error', error.response.data.message);
      serializeAndShowFormErrors(error);
      throw error;
    }
  },

  getCourses: (force: boolean, preApplyPromoCode: boolean) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (
      !force &&
      (!state.authUser.user ||
        !state.authUser.user.currentStage ||
        state.authUser.user.currentStage ===
          originalRegistrationStages.profileDetails)
    ) {
      return;
    }
    dispatch({ type: types.GET_COURSES_REQUEST });
    try {
      const response = await api.registration.getCourses(preApplyPromoCode);
      dispatch({
        type: types.GET_COURSES_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      dispatch({ type: types.GET_COURSES_ERROR });
      serializeAndShowFormErrors(error);
      throw error;
    }
  },

  selectCourse: (
    course: StudentCourse,
    courseType: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.CHOOSE_COURSE_REQUEST,
    });
    try {
      const data = {
        courseType,
        courseCode: course.course.code,
      };
      const response = await api.registration.chooseCourse(data);
      const referralList: [] = [];
      const referralCode = '';
      const referralAmount = 0;
      dispatch({
        type: types.CHOOSE_COURSE_SUCCESS,
        data: {
          course: response.data.userCourseData,
          tokenBalanceAmount: response?.data?.tokenBalanceAmount,
          courseType,
          referralList,
          referralCode,
          referralAmount,
        },
      });
      if (onSuccess) {
        onSuccess();
      }
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.CHOOSE_COURSE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // console.log('error Logging in...', error.response.data.message);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },

  changePaymentPlan: (plan: string) => (dispatch: Dispatch) => {
    dispatch({
      type: types.CHANGE_PAYMENT_PLAN,
      data: plan,
    });
  },
  changePaymentMethod: (mode: paymentModes) => (dispatch: Dispatch) => {
    dispatch({
      type: types.CHANGE_PAYMENT_METHOD,
      data: mode,
    });
  },
  selectMilestone: (milestone: any, selected: boolean) => (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: types.SELECT_MILESTONE,
      data: { milestone, selected },
    });
  },

  initiatePayment: (
    milestones: any,
    totalAmount: number,
    courseType: string,
    promotion: any,
    retryPayment?: boolean,
    promoCode?: string,
    referralAmount?: number,
    referralList?: any,
    referralCode?: string
  ) => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const isOnlinePayment: boolean =
      state.registration.modeOfPayment === paymentModes.online;
    dispatch({ type: types.PAYMENT_INITIATE_REQUEST });
    try {
      const data = {
        courseType,
        milestones,
        amount: totalAmount,
        promotionId: promotion != null ? promotion._id : null,
        isOnlinePayment,
        redirectUrl: getHost() + RouteKeys.RegAdditionalPaymentCallback,
        retryPayment,
        platform: 'web',
        referralAmount,
        referralList: referralList?.length > 0 ? referralList : [],
        referralCode,
      };
      const userResponse = await api.user.getStudentCourse();
      if (
        userResponse.data &&
        userResponse.data.course &&
        userResponse.data.course.isAddedInErp
      ) {
        dispatch({ type: types.PAYMENT_INITIATE_ERROR });
        throw new Error('User has already done payment');
      }
      const response = await api.registration.initiatePayment(data);
      dispatch({ type: types.PAYMENT_INITIATE_SUCCESS, data: response.data });
      // toastr.success('Success', 'You are now Logged In!');
      if (!isOnlinePayment) {
        trackPurchase(
          totalAmount,
          state.authUser.user ? state.authUser.user._id || '' : '',
          promoCode || '',
          state.registration.paymentPlan
        );
      }
    } catch (error) {
      dispatch({ type: types.PAYMENT_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },

  clearPromoCode: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.PROMOCODE_INITIATE_REQUEST });
    try {
      const data = {};
      const response = await api.registration.getPromotions(data);
      // const response = [];
      dispatch({ type: types.PROMOCODE_INITIATE_SUCCESS, data: response.data });
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.PROMOCODE_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },
  applyPromoCode: (promoCode: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.APPLY_PROMOCODE_INITIATE_REQUEST });
    try {
      if (!promoCode) {
        dispatch({ type: types.APPLY_PROMOCODE_CLOSE });
      } else {
        const data = { promoCode };
        const response = await api.registration.applyPromotion(data);
        dispatch({
          type: types.APPLY_PROMOCODE_INITIATE_SUCCESS,
          data: response.data.userCourseData,
          promoCode,
        });
      }
      // toastr.success('Success', 'You are now Logged In!');
    } catch (error) {
      dispatch({ type: types.APPLY_PROMOCODE_INITIATE_ERROR });
      /* toast the error messages */
      // console.log('error Logging in...', error);
      // console.log('error Logging in(response)...', error.response);
      // toastr.error('Error', 'Error Verifying OTP');
      throw error;
    }
  },

  verifyPayment: (
    paymentReferenceData: PaymentReferenceInfo,
    retryPayment: boolean,
    onSuccess?: () => void,
    referralAmount?: number,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.VERIFY_PAYMENT_REQUEST });
    try {
      const response = await api.payment.verifyPayment(
        paymentReferenceData.ref as string,
        retryPayment,
        referralAmount
      );
      if (response && response.data && response.data.isSuccess) {
        dispatch({ type: types.VERIFY_PAYMENT_SUCCESS, data: response.data });
        if (onSuccess) {
          onSuccess();
        }
      } else {
       
        toastr.error('Payment Error', response?.data?.responseText);
        dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
        if (onError) {
          onError();
        }
      }
      // else if (
      //   response.data.responseText === paymentErrorCodes.threeDSErrorOne ||
      //   response.data.responseText === paymentErrorCodes.threeDSErrorTwo
      // ) {
      //   toastr.error('Payment Error', paymentErrors.THREE_DS_NOT_AUTHENTICATED);
      //   dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      // } else if (response.data.responseText === paymentErrorCodes.authError) {
      //   toastr.error('Payment Error', paymentErrors.AUTHORISATION_FAILED);
      //   dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      // } else {
      //   toastr.error('Payment Error', response.data.responseText);
      //   dispatch({ type: types.VERIFY_PAYMENT_FAILED, data: response.data });
      // }
    } catch (error:any) {
      let errorMessage = 'Something went wrong, Please try again!';
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
      toastr.error('Payment Error', errorMessage);
      dispatch({ type: types.VERIFY_PAYMENT_ERROR });
      if (onError) {
        onError();
      }
    }
  },
  setCourse: (
    course: StudentCourse,
    courseType: string,
    walletAmount: number
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.CHOOSE_COURSE_SUCCESS,
      data: { course, courseType, tokenBalanceAmount: walletAmount },
    });
  },

  showReferralBox: (hasReferral: any) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.HAS_REFERRAL,
      data: { hasReferral },
    });

    if (!hasReferral) {
      dispatch({ type: types.APPLY_REFERRAL_INITIATE_REQUEST });
      try {
        const referral = '';
        const data = { referral };
        const referralList = '';
        const referralCode = '';
        const referralAmount = 0;
        dispatch({
          type: types.APPLY_REFERRAL_INITIATE_SUCCESS,
          data: { referralList, referralCode, referralAmount },
        });
        dispatch({ type: types.APPLY_REFERRAL_CLOSE });
        toastr.success('Success', 'Referral Removed Successfully !');
      } catch (error) {
        dispatch({ type: types.APPLY_REFERRAL_INITIATE_ERROR });

        // toastr.error('Error', error.response.data.message);
        throw error;
      }
    }
  },
  applyReferral: (referral: any, referralType: any) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.APPLY_REFERRAL_INITIATE_REQUEST });
    try {
      if (!referral) {
        dispatch({ type: types.APPLY_REFERRAL_CLOSE });
      } else {
        const data = { referral, referralType };
        const response = await api.registration.applyReferral(data);
        const referralList = [response.data.referralList[0]];
        const referralAmount =
          response.data.referralList?.length > 0
            ? response.data.referralList[0]?.refereeAmount
            : 0;
        const referralCode = referral;

        dispatch({
          type: types.APPLY_REFERRAL_INITIATE_SUCCESS,
          data: { referralList, referralCode, referralAmount, referralType },
        });
      }
      toastr.success('Success', 'Referral Applied Successfully !');
    } catch (error) {
      const typedError = error as ErrorResponse;

      dispatch({ type: types.APPLY_REFERRAL_INITIATE_ERROR });
      toastr.error('Error', typedError?.response.data.message);
      throw error;
    }
  },

  removeReferral: (referral: any) => async (dispatch: Dispatch) => {
    dispatch({ type: types.APPLY_REFERRAL_INITIATE_REQUEST });
    try {
      if (!referral) {
        dispatch({ type: types.APPLY_REFERRAL_CLOSE });
      } else {
        const referralList = '';
        const referralCode = '';
        const referralAmount = 0;
        dispatch({
          type: types.APPLY_REFERRAL_INITIATE_SUCCESS,
          data: { referralList, referralCode, referralAmount },
        });
      }

      dispatch({
        type: types.HAS_REFERRAL,
        data: { hasReferral: false },
      });

      toastr.success('Success', 'Referral Removed Successfully !');
    } catch (error) {
      dispatch({ type: types.APPLY_REFERRAL_INITIATE_ERROR });
      // toastr.error('Error', error.response.data.message);
      throw error;
    }
  },
  updatePreregistrationDetails: (
    userData: any,
    onSuccess: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.UPDATE_DETAILS_REQUEST });
    try {
      const response = await api.registration.updatePreregistrationDetails(
        userData
      );
      dispatch({
        type: types.UPDATE_DETAILS_SUCCESS,
        data: response.data.userRecord,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response);
      // console.log('error response: ', error.response);
      dispatch({ type: types.UPDATE_DETAILS_ERROR });
      // toastr.error('Error', error.response.data.message);
      serializeAndShowFormErrors(error);
      throw error;
    }
  },
};

export function serializeAndShowFormErrors(error: any) {
  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;
    Object.keys(errors).forEach((e) => {
      // console.log('error: ', e, errors[e].message);
      toastr.error(errors[e].message, '');
    });
    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}

import React from 'react';
import PaymentIFrame from '../../components/payment-iframe';
import './PaymentMethod.scss';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../store/interfaces';
import {
  OnlinePaymentStatus,
  actions as registrationActions,
} from '../../store/reducers/registration';
import { PaymentMessage } from '../../models/payment';
import { ContainerLoader } from '../../components/loader';
import { trackPurchase } from '../../utils/analytics';

interface CustomProps {
  gotoPreviousBlock?: () => void;
}

const PaymentMethod: React.SFC<CustomProps> = (props: CustomProps) => {
  const {
    onlinePaymentInfo,
    onlinePaymentStatus,
    summary,
    paymentPlan,
    promoCode,
    referralAmount,
  } = useSelector((state: State) => state.registration);
  const { user } = useSelector((state: State) => state.authUser);

  const referralPayingAmount = referralAmount || 0;

  const payingAmount =
    summary.totalAmount +
    summary.totalTax -
    summary.totalDiscount -
    referralPayingAmount;

  const dispatch = useDispatch();

  const completePayment = (data: any) => {
    const retryPayment = onlinePaymentStatus === 'failed' ? true : false;
    dispatch(
      registrationActions.verifyPayment(
        data.data,
        retryPayment,
        () => {
          trackPurchase(
            payingAmount,
            user ? user._id || '' : '',
            promoCode || '',
            paymentPlan
          );
        },
        referralPayingAmount
      )
    );
  };

  if (onlinePaymentStatus === OnlinePaymentStatus.failed) {
    // Registration
    if (props.gotoPreviousBlock) {
      props.gotoPreviousBlock();
    }
  }
  if (
    !onlinePaymentInfo &&
    (!onlinePaymentStatus ||
      onlinePaymentStatus === OnlinePaymentStatus.processing)
  ) {
    return (
      <ContainerLoader height={800} text={'Please wait...'}></ContainerLoader>
    );
  }

  if (onlinePaymentStatus !== OnlinePaymentStatus.processing) {
    const loadingText =
      onlinePaymentStatus === OnlinePaymentStatus.initiated
        ? 'Please wait while we loading payment data..'
        : onlinePaymentStatus === OnlinePaymentStatus.verifying
        ? 'Please wait, we are verifying your payment..'
        : '';
    return <ContainerLoader height={800} text={loadingText}></ContainerLoader>;
  }

  return (
    <div className="pt-5 sm:pt-7 ltr:md:pl-10 rtl:md:pr-10">
      <PaymentIFrame
        className="border-0"
        id="registration-payment"
        url={onlinePaymentInfo.href}
        width="100%"
        height="840px"
        onCompletePayment={completePayment}
      ></PaymentIFrame>
    </div>
  );
};

export default PaymentMethod;
